export const paginationDisplaying = [
	{
		value: "5",
		label: "5",
	},
	{
		value: "10",
		label: "10",
	},
	{
		value: "20",
		label: "20",
	},
	{
		value: "30",
		label: "30",
	},
	{
		value: "40",
		label: "40",
	},
	{
		value: "50",
		label: "50",
	},
];
