import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { CityEmpty, ICity, IServiceResponse } from '@2ndmarket/types';

const useCity = (
  cityId: number | null
): IServiceResponse<ICity> => {
  const key = cityId
    ? {
        endpoint: `/v4/locale/cities/${cityId}`,
      }
    : null;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<ICity>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? CityEmpty,
    loading: !error && !data,
    error,
    mutate: mutate,
  };
};

export default useCity;
