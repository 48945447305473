import { useState } from 'react';

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';

interface OptionProps {
  label: string;
  icon?: string | undefined;
  value: string | number | string[];
}

export type SelectProps = {
  value: string;
  options: OptionProps[];
  onChange: (value: string) => void;
};

const SelectPagination: React.FC<SelectProps> = ({
  value,
  options,
  onChange,
}) => {
  const Parent = styled(Box)({
    width: '100%',
    height: '32px',
    position: 'relative',
  });

  const SelectCustom = styled(Select)({
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    borderRadius: '3px',
    '.MuiSelect-select': {
      fontSize: '14px',
    },
    left: 0,
    appearance: 'none',
    position: 'absolute',
    transition: 'all 0.25s ease',
    backgroundColor: 'transparent',
  });

  const [val, setVal] = useState();

  const changeSelect = (selectedValue: any) => {
    setVal(selectedValue);
    onChange(selectedValue);
  };

  return (
    <Parent>
      <SelectCustom
        value={val || value}
        onChange={e => changeSelect(e.target.value)}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </SelectCustom>
    </Parent>
  );
};

export default SelectPagination;
