import authenticatedFetcher from '../authenticatedFetcher';

interface Params {
  name: string;
  state_id: number;
}

const createCity: (params: Params) => Promise<unknown> =
  function (params) {
    return authenticatedFetcher({
      endpoint: '/v4/locale/cities',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(params),
    });
  };

export default createCity;
