import authenticatedFetcher from '../authenticatedFetcher';

import { IBank } from '@2ndmarket/types';

const createBank: (params: IBank) => Promise<unknown> =
  function (params) {
    return authenticatedFetcher({
      endpoint: '/v4/locale/banks',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(params),
    });
  };

export default createBank;
