import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';

import {
  IRole,
  RoleEmpty,
  IServiceResponse,
} from '@2ndmarket/types';

const useRole = (id: number | null): IServiceResponse<IRole> => {
  const key = id ? { endpoint: `/v4/roles/${id}` } : null;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IRole, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? RoleEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useRole;
