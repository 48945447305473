import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';

import {
  IServiceResponse,
  IStaffs,
  StaffsEmpty,
} from '@2ndmarket/types';

const useStaffs = (
  filter: string = '',
  ordering: string = 'asc',
  status: string = '',
  page: number = 1,
  pageSize: number = 20,
): IServiceResponse<IStaffs> => {
  const params = new URLSearchParams();
  params.append('filter', filter);
  params.append('order', ordering);
  if (status && status != 'all') {
    params.append('status', status);
  }
  params.append('page', page.toString());
  params.append('page_size', pageSize.toString());

  const key = {
    endpoint: `/v4/staff?${params}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IStaffs, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? StaffsEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useStaffs;
