import { ColumnProps } from "@2ndmarket/types";

export const TableColumnCityList: ColumnProps[] = [
	{
		title: "ID",
		accessor: "id",
	},
	{
		title: "Cidade",
		accessor: "name",
	},
	{
		title: "Estado",
		accessor: "state.name",
	},
];
