import { ColumnProps } from "@2ndmarket/types";

export const TableColumnBankList: ColumnProps[] = [
	{
		title: "ID",
		accessor: "id",
	},
	{
		title: "Banco",
		accessor: "name",
	},
	{
		title: "Código",
		accessor: "code",
	},
];
