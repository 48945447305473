import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import {
  IScope,
  IServiceResponse,
  ScopeEmpty,
} from '@2ndmarket/types';

const useScope = (
  scope_id: number,
): IServiceResponse<IScope> => {
  const key = {
    endpoint: `/v4/scopes/${scope_id}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IScope, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? ScopeEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useScope;
