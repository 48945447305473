export interface IRole {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  type: string;
  description: string;
}

export const RoleEmpty: IRole = {
  id: 0,
  created_at: '',
  updated_at: '',
  name: '',
  type: '',
  description: '',
};

export interface RoleIdProps {
  role_id: number;
}
