import authenticatedFetcher from '../authenticatedFetcher';

// interface CreateRoleProps {
//   name: string;
//   description: string;
//   type: string;
//   role_permissions?: [
//     permission_ids?: number,
//     role_id?: number,
//     own_activate?: boolean,
//     own_list?: boolean,
//     own_create?: boolean,
//     own_read?: boolean,
//     own_update?: boolean,
//     own_delete?: boolean,
//     other_activate?: boolean,
//     other_list?: boolean,
//     other_create?: boolean,
//     other_read?: boolean,
//     other_update?: boolean,
//     other_delete?: boolean,
//   ];
// }

const createRole = function (
  name: string,
  description: string,
  type: string,
) {
  return authenticatedFetcher({
    endpoint: '/v4/roles',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      name: name,
      description: description,
      type: type,
    }),
  });
};

export default createRole;
