import dayjs from "dayjs";
import ptBR from "dayjs/locale/pt-br";

import { ColumnProps } from "@2ndmarket/types";

const cpf = /(\d{3})(\d{3})(\d{3})(\d{2})/;
const cnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

const customCpfCnpj = (value: string) => {
	return value.replace(cpf, "$1.$2.$3-$4");
};

const customDate = (value: string) => {
	return dayjs(value).locale(ptBR).format("DD/MM/YYYY");
};

const customHours = (value: string) => {
	return dayjs(value).locale(ptBR).format("HH:mm");
};

export const TableColumnTeamList: ColumnProps[] = [
	{
		title: "Nome",
		accessor: "name",
		minWidth: 80,
		maxWidth: 80,
	},
	{
		title: "E-mail",
		accessor: "email",
		minWidth: 100,
		maxWidth: 100,
	},
	{
		title: "CPF",
		accessor: "cpf",
		Cell: ({ value }) => (!value ? "-" : customCpfCnpj(value)),
		minWidth: 170,
		maxWidth: 170,
	},
	{
		title: "Data do Cad.",
		accessor: "created_at",
		Cell: ({ value }) =>
			customDate(value) + " " + customHours(value),
		minWidth: 180,
		maxWidth: 180,
	},
];
