import { ColumnProps } from "@2ndmarket/types";

export const TableColumnPermissionList: ColumnProps[] = [
	{
		title: "ID",
		accessor: "id",
	},
	{
		title: "Nome",
		accessor: "name",
	},
	{
		title: "Descrição",
		accessor: "description",
	},
];
