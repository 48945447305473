import authenticatedFetcher from '../authenticatedFetcher';

const createCountry = function (
  name: string,
  nationality: string,
) {
  return authenticatedFetcher({
    endpoint: '/v4/locale/countries',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      name: name,
      nationality: nationality,
    }),
  });
};

export default createCountry;
