import authenticatedFetcher from '../authenticatedFetcher';

interface updatePhoneProps {
  ddi?: string;
  ddd?: string;
  number?: string;
  status?: string;
}

const updatePhone: (
  user_id: number,
  params: updatePhoneProps,
) => Promise<any> = (user_id, params) => {
  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/phone`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};

export default updatePhone;
