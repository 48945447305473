import authenticatedFetcher from '../authenticatedFetcher';

const getStaffMePin = function (user_id: number) {
  return authenticatedFetcher({
    endpoint: `/v4/staff/${user_id}/pin`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
};

export default getStaffMePin;
