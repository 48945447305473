import authenticatedFetcher from '../authenticatedFetcher';

interface UpdateOnboardingProps {
  onboarding_basic: {
    profile_step?: string;
    address_step?: string;
    document_step?: string;
    phone_step?: string;
    pin_step?: string;
    status?: string;
    status_message?: string;
  };
}

const updateOnboarding: (
  user_id: number,
  params: UpdateOnboardingProps,
) => Promise<any> = (user_id, params) => {
  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/onboarding`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(params),
  });
};

export default updateOnboarding;
