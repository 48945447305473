import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';

import {
  IServiceResponse,
  StateEmpty,
  StateProps,
} from '@2ndmarket/types';

const useState = (
  stateId: any,
): IServiceResponse<StateProps> => {
  const key = stateId
    ? {
        endpoint: `/v4/locale/states/${stateId}`,
      }
    : null;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<StateProps>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? StateEmpty,
    loading: !error && !data,
    error,
    mutate: mutate,
  };
};

export default useState;
