import type { AppProps } from 'next/app';
import { useEffect, useState } from 'react';

import { AppContext } from '@2ndmarket/helpers';
import { useStaffMe } from '@2ndmarket/services';
import { GoogleTagManager, themes } from '@2ndmarket/components';

import CssBaseline from '@mui/material/CssBaseline';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { ThemeProvider, authentication } = themes;

  const [showBalance, setShowBalance] = useState(false);

  useEffect(() => {
    const storageShowBalance = localStorage.getItem(
      '@2ndMarket:showBalance',
    );

    if (storageShowBalance == 'true') {
      setShowBalance(true);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        showBalance: showBalance,
        setShowBalance: setShowBalance,
      }}
    >
      <ThemeProvider theme={authentication}>
        <GoogleTagManager
          userOrigin={useStaffMe}
          theme={authentication}
        />
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default MyApp;
