import { ColumnProps } from "@2ndmarket/types";

export const TableColumnStateList: ColumnProps[] = [
	{
		title: "ID",
		accessor: "id",
	},
	{
		title: "Estado",
		accessor: "name",
	},
	{
		title: "Sigla",
		accessor: "initials",
	},
	{
		title: "País",
		accessor: "country.name",
	},
];
