import { ColumnProps } from "@2ndmarket/types";

export const TableColumnRoleList: ColumnProps[] = [
	{
		title: "ID",
		accessor: "id",
	},
	{
		title: "Função",
		accessor: "name",
	},
	{
		title: "Descrição",
		accessor: "description",
	},
	{
		title: "Permissões",
		accessor: "type",
	},
];
