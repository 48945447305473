export const staffRoles = [
	{
		id: 1,
		type: "staff",
		name: "admin",
		label: "Administrador",
		description: "",
	},
	{
		id: 2,
		type: "user",
		name: "default",
		label: "Membro",
		description: "",
	},
	{
		id: 3,
		type: "app",
		name: "application",
		label: "Aplicação",
		description: "",
	},
];
