import authenticatedFetcher from '../authenticatedFetcher';

interface Params {
  name?: string;
  state_id?: number;
}

const updateCity: (
  id: number,
  params: Params,
) => Promise<unknown> = function (id, params) {
  return authenticatedFetcher({
    endpoint: `/v4/locale/cities/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(params),
  });
};

export default updateCity;
