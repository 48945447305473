import authenticatedFetcher from '../authenticatedFetcher';

const updateState = function (
  state_id: any,
  name: string,
  initials: string,
  country_id: string,
) {
  return authenticatedFetcher({
    endpoint: `/v4/locale/states/${state_id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      name: name,
      initials: initials,
      country_id: country_id,
    }),
  });
};

export default updateState;
