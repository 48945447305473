import authenticatedFetcher from '../authenticatedFetcher';

const updateStaff = function (
  staff_id: any,
  cpf: string,
  email: string,
  name: string,
  password: string,
  phone: string,
  role_id: number,
  status?: string,
) {
  return authenticatedFetcher({
    endpoint: `/v4/staff/${staff_id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      name: name,
      email: email,
      cpf: cpf,
      phone: phone,
      password: password,
      role_id: role_id,
    }),
  });
};

export default updateStaff;
