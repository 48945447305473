import { forwardRef } from 'react';
import {
  NumericFormat,
  NumericFormatProps,
} from 'react-number-format';

interface CustomProps {
  onChange: (event: {
    target: { name: string; value: string };
  }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange } = props;

  return (
    <NumericFormat
      {...props}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      lang="pt-BR"
      prefix="R$ "
    />
  );
});

export default NumericFormatCustom;
