export interface ICity {
  name: string;
  state_id: number;
  state: {
    id: number;
    name: string;
    initials: string;
    country: {
      name: string;
      nationality: string;
      id: number;
    };
  };
}

export interface CityIdProps {
  city_id: number;
}

export const CityEmpty: ICity = {
  name: '',
  state_id: 0,
  state: {
    id: 0,
    name: '',
    initials: '',
    country: {
      name: '',
      nationality: '',
      id: 0,
    },
  },
};
