import authenticatedFetcher from '../authenticatedFetcher';

const updateScope = function (
  scope_id: number,
  name: string,
  description: string,
) {
  return authenticatedFetcher({
    endpoint: `/v4/scopes/${scope_id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      name: name,
      description: description,
    }),
  });
};

export default updateScope;
