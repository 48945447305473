import authenticatedFetcher from '../authenticatedFetcher';

const userDeletePin = function (user_id: number) {
  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/pin`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  });
};

export default userDeletePin;
