import authenticatedFetcher from '../authenticatedFetcher';

const createStaff = function (
  cpf: string,
  email: string,
  name: string,
  password: string,
  phone: string,
  role: string,
) {
  return authenticatedFetcher({
    endpoint: '/v4/staff',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      cpf: cpf,
      email: email,
      name: name,
      password: password,
      phone: phone,
      role: role,
    }),
  });
};

export default createStaff;
