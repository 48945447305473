import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';

import {
  IServiceResponse,
  ICountry,
  CountryEmpty,
} from '@2ndmarket/types';

const useCountry = (
  countryId: any,
): IServiceResponse<ICountry> => {
  const key = countryId
    ? {
        endpoint: `/v4/locale/countries/${countryId}`,
      }
    : null;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<ICountry, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? CountryEmpty,
    loading: !error && !data,
    error,
    mutate: mutate,
  };
};

export default useCountry;
