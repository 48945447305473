import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import {
  OnboardingProgress,
  UserStatus,
  RegisterType,
  Status,
} from '@2ndmarket/helpers';
import {
  IUsers,
  UsersEmpty,
  IServiceResponse,
} from '@2ndmarket/types';

const useUsers = (
  personType?: RegisterType,
  userStatus?: UserStatus,
  onboardingProgress?: OnboardingProgress,
  onboardignProgressStatus?: Status,
  filter: string = '',
  ordering: string = 'asc',
  page: number = 1,
  pageSize: number = 20,
): IServiceResponse<IUsers> => {
  const params = new URLSearchParams();

  params.append('ordering', ordering);
  params.append('page', page.toString());
  params.append('page_size', pageSize.toString());

  if (filter) {
    params.append('filter', filter);
  }

  if (userStatus) {
    params.append('user_status', userStatus);
  }

  if (onboardingProgress) {
    params.append('onboarding_progress', onboardingProgress);
  }

  if (onboardignProgressStatus) {
    params.append(
      'onboarding_progress_status',
      onboardignProgressStatus,
    );
  }

  if (personType) {
    params.append('person_type', personType);
  }

  const key = {
    endpoint: `/v4/users?${params}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IUsers, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? UsersEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useUsers;
