import authenticatedFetcher from '../authenticatedFetcher';

const updateCountry = function (
  id: any,
  name: string,
  nationality: string,
) {
  return authenticatedFetcher({
    endpoint: `/v4/locale/countries/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      name: name,
      nationality: nationality,
    }),
  });
};

export default updateCountry;
