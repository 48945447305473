export interface ICountry {
  name: string;
  nationality: string;
}

export interface CountryIdProps {
  country_id: number;
}

export const CountryEmpty: ICountry = {
  name: '',
  nationality: '',
};
