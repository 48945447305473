import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { IRoles, IServiceResponse, RolesEmpty } from '@2ndmarket/types';

const useRoles = (
  filter: string = '',
  order: string = 'asc',
  page_size: number = 100,
  page: number = 1,
): IServiceResponse<IRoles> => {
  const key = {
    endpoint: `/v4/roles?filter=${filter}&ordering=${order}&page=${page}&page_size=${page_size}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IRoles, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? RolesEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useRoles;
