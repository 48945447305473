import { ColumnProps } from "@2ndmarket/types";

export const TableColumnCountryList: ColumnProps[] = [
	{
		title: "ID",
		accessor: "id",
	},
	{
		title: "País",
		accessor: "name",
	},
	{
		title: "Nacionalidade",
		accessor: "nationality",
	},
];
