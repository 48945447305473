import useSWR from 'swr';

import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

import {
  IStaff,
  StaffEmpty,
  IServiceResponse,
} from '@2ndmarket/types';

const useStaffMe = (
  user_id: number | AppConstants.ME = AppConstants.ME,
): IServiceResponse<IStaff> => {
  const key = {
    endpoint: `/v4/staff/${user_id}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IStaff, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? StaffEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useStaffMe;
