import authenticatedFetcher from '../authenticatedFetcher';

interface Params {
  name?: string;
  code?: string;
  mask_branch?: string;
  mask_account?: string;
}

const updateBank: (
  bank_id: number,
  params: Params,
) => Promise<unknown> = function (bank_id, params) {
  return authenticatedFetcher({
    endpoint: `/v4/locale/banks/${bank_id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(params),
  });
};

export default updateBank;
