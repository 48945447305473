export interface IPermissionsResults {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
}

export interface IPermissions {
  totalRecords: number;
  pageSize: number;
  startRecord: number;
  lastPage: number;
  currentPage: number;
  results: Array<IPermissionsResults>;
}

export const PermissionsEmpty: IPermissions = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  results: [],
};

export interface IScope {
  id: number;
  name: string;
  description: string;
}

export const ScopeEmpty: IScope = {
  id: 0,
  name: '',
  description: '',
};

export interface ScopeIdProps {
  scope_id: number;
}
