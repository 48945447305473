import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import {
  IPermissions,
  IServiceResponse,
  PermissionsEmpty,
} from '@2ndmarket/types';

const useScopes = (
  filter: string = '',
  ordering: string = 'asc',
  page: number = 1,
  page_size: number = 20,
): IServiceResponse<IPermissions> => {
  const params = new URLSearchParams();
  params.append('filter', filter);
  params.append('order', ordering);
  params.append('page', page.toString());
  params.append('page_size', page_size.toString());

  const key = {
    endpoint: `/v4/scopes?${params}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IPermissions, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? PermissionsEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useScopes;
