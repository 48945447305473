import dayjs from "dayjs";
import ptBR from "dayjs/locale/pt-br";

import { ColumnProps } from "@2ndmarket/types";
import { OnboardingProgress } from "@2ndmarket/helpers";

const cpf = /(\d{3})(\d{3})(\d{3})(\d{2})/;
const cnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

const customCpfCnpj = (value: string) => {
	if (value.length > 11) {
		return value.replace(cnpj, "$1.$2.$3/$4-$5");
	}

	return value.replace(cpf, "$1.$2.$3-$4");
};

const customDate = (value: string) => {
	return dayjs(value).locale(ptBR).format("DD/MM/YYYY");
};

const customHours = (value: string) => {
	return dayjs(value).locale(ptBR).format("HH:mm");
};

export const TableColumnUserList: ColumnProps[] = [
	{
		title: "",
		accessor: "status",
		Cell: ({ value }) => {},
		minWidth: 20,
		maxWidth: 20,
	},
	{
		title: "ID",
		accessor: "id",
		minWidth: 80,
		maxWidth: 80,
	},
	{
		title: "Nome",
		accessor: "name",
		minWidth: 80,
		maxWidth: 80,
	},
	{
		title: "E-mail",
		accessor: "email",
		minWidth: 100,
		maxWidth: 100,
	},
	{
		title: "CPF/CNPJ",
		accessor: "cpf_cnpj",
		Cell: ({ value }) => customCpfCnpj(value),
		minWidth: 170,
		maxWidth: 170,
	},
	{
		title: "Data do Cad.",
		accessor: "created_at",
		Cell: ({ value }) =>
			customDate(value) + " " + customHours(value),
		minWidth: 180,
		maxWidth: 180,
	},
	{
		title: "Etapa do Cad.",
		accessor: "onboarding_progress",
		Cell: ({ value }) => {
			switch (value) {
				case OnboardingProgress.NEWUSER:
					return "Novo Usuário";
				case OnboardingProgress.BASIC:
					return "Cadastro Básico";
				case OnboardingProgress.DIGITAL:
					return "Conta Digital";
				case OnboardingProgress.BANK:
				default:
					return "Conta Bancária";
			}
		},
		minWidth: 150,
		maxWidth: 150,
	},
];
