import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';

const usePermission = (role_id: number | null) => {
  const key = role_id
    ? {
        endpoint: `/v4/permissions?page_size=10&page=1&role_id=${role_id}&ordering=asc`,
      }
    : null;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default usePermission;
