import authenticatedFetcher from '../authenticatedFetcher';

interface UpdateRoleProps {
  permission_ids?: number;
  role_id?: number;
  own_activate?: boolean;
  own_list?: boolean;
  own_create?: boolean;
  own_read?: boolean;
  own_update?: boolean;
  own_delete?: boolean;
  other_activate?: boolean;
  other_list?: boolean;
  other_create?: boolean;
  other_read?: boolean;
  other_update?: boolean;
  other_delete?: boolean;
}

const updateScope = function (
  role_id: number,
  params: UpdateRoleProps,
) {
  for (const key in params) {
    if (key == 'role_id') {
      params['role_id'] = role_id;
    }
  }

  return authenticatedFetcher({
    endpoint: `/v4/roles/${role_id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      ...params,
    }),
  });
};

export default updateScope;
