import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';

const useCountries = (
  filter: string = '',
  order: string = 'asc',
  page: number = 1,
  page_size: number = 20,
) => {
  const params = new URLSearchParams();
  params.append('filter', filter);
  params.append('order', order);
  params.append('page', page.toString());
  params.append('page_size', page_size.toString());
  const key = {
    endpoint: `/v4/locale/countries?${params}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data,
    loading: !error && !data,
    error,
    mutate: mutate,
  };
};

export default useCountries;
