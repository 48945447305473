export interface IRolesResults {
  id: number,
  created_at: string,
  updated_at: string,
  name: string,
  type: string,
  description: string
}

export interface IRoles {
  totalRecords: number,
  pageSize: number,
  startRecord: number,
  lastPage: number,
  currentPage: number,
  results: Array<IRolesResults>,
}

export const RolesEmpty: IRoles = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  results: [],
};