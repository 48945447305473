import authenticatedFetcher from '../authenticatedFetcher';

const createPermission = function (
  name: string,
  description: string,
) {
  return authenticatedFetcher({
    endpoint: '/v4/permissions',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      name: name,
      description: description,
    }),
  });
};

export default createPermission;
