import authenticatedFetcher from '../authenticatedFetcher';

const createState = function (
  name: string,
  initials: string,
  country_id: string,
) {
  return authenticatedFetcher({
    endpoint: '/v4/locale/states',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      name: name,
      initials: initials,
      country_id: country_id,
    }),
  });
};

export default createState;
