export interface IState {
  name: string;
  initials: string;
  country: {
    id: string;
    name: string;
    nationality: string;
  };
}

export interface StateIdProps {
  state_id: number;
}

export const StateEmpty: IState = {
  name: '',
  initials: '',
  country: {
    id: '',
    name: '',
    nationality: '',
  },
};
